import { createRouter, createWebHistory } from "vue-router";
import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_ACCOUNT,
  ROUTE_ACCOUNT_BANKACCOUNTS,
  ROUTE_ACCOUNT_CHANGE_BANKACCOUNTS,
  ROUTE_ACCOUNT_DATA,
  ROUTE_DASHBOARD,
  ROUTE_LOGIN,
  ROUTE_LOOKAHEAD,
  ROUTE_ONBOARDING,
  ROUTE_RESET_PASSWORD,
  ROUTE_TRANSACTIONS,
  ROUTE_UPDATE_PASSWORD,
  ROUTE_WEEKFLOW,
  ROUTE_WELCOME,
} from "@/constants/routes";
import { useAuthStore } from "@/stores/useAuthStore";
import { storeToRefs } from "pinia";
import mitt from "@/utils/mitt";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/DefaultLayout.vue"),
    children: [
      {
        path: ROUTE_DASHBOARD,
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/DashboardView.vue"),
      },
      {
        path: ROUTE_WEEKFLOW,
        name: "weekflow",
        component: () => import(/* webpackChunkName: "weekflow" */ "@/views/WeekflowView.vue"),
      },
      {
        path: ROUTE_LOOKAHEAD,
        name: "lookAhead",
        component: () => import(/* webpackChunkName: "lookahead" */ "@/views/LookAheadView.vue"),
      },
      {
        path: ROUTE_TRANSACTIONS,
        name: "bankAccountTransactions",
        component: () => import(/* webpackChunkName: "transactions" */ "@/views/BankAccountTransactionsView.vue"),
        props: true,
      },
      {
        path: ROUTE_ACCOUNT_ACCOUNT,
        name: "account",
        component: () => import(/* webpackChunkName: "account" */ "@/views/Account/AccountView.vue"),
      },
      {
        path: ROUTE_ACCOUNT_DATA,
        name: "account-data",
        component: () => import(/* webpackChunkName: "account" */ "@/views/Account/ChangeFinancialDataView.vue"),
      },
      {
        path: ROUTE_ACCOUNT_BANKACCOUNTS,
        name: "bankaccounts",
        component: () => import(/* webpackChunkName: "bankAccounts" */ "@/views/Account/BankAccountsView.vue"),
      },
      {
        path: ROUTE_ACCOUNT_CHANGE_BANKACCOUNTS,
        name: "change-bankaccounts",
        component: () => import(/* webpackChunkName: "bankAccounts" */ "@/views/Account/ChangeBankAccountsView.vue"),
      },
      {
        path: ROUTE_ACCOUNT,
        name: "overview",
        component: () => import(/* webpackChunkName: "overView" */ "@/views/Account/OverviewView.vue"),
      },
      {
        path: ROUTE_UPDATE_PASSWORD,
        name: "update-password",
        component: () => import(/* webpackChunkName: "updatePassword" */ "@/views/Account/UpdatePasswordView.vue"),
      },
    ],
  },
  {
    path: ROUTE_ONBOARDING,
    component: () => import("@/layouts/default/TopbarLayout.vue"),
    children: [
      {
        path: ROUTE_WELCOME,
        name: "welcome",
        component: () => import(/* webpackChunkName: "onboardingWelcome" */ "@/views/WelcomeView.vue"),
      },
      {
        path: ROUTE_ONBOARDING,
        name: "onboardingStepOne",
        component: () => import(/* webpackChunkName: "onboarding" */ "@/views/OnboardingView.vue"),
      },
    ],
  },
  {
    path: "/styleguide",
    component: () => import("@/layouts/default/BlankLayout.vue"),
    children: [
      {
        path: "",
        name: "styleguide",
        component: () => import(/* webpackChunkName: "styleguide" */ "@/views/StyleguideView.vue"),
      },
    ],
  },
  {
    path: ROUTE_LOGIN,
    component: () => import("@/layouts/default/BlankLayout.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "@/views/LoginView.vue"),
      },
      {
        path: ROUTE_RESET_PASSWORD,
        name: "resetPassword",
        component: () => import(/* webpackChunkName: "resetPassword" */ "@/views/Account/ResetPasswordView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  const authStore = useAuthStore();
  const { isAuthenticated } = storeToRefs(authStore);

  if (!localStorage.getItem("accessToken")) {
    isAuthenticated.value = false;
  }

  /**
   * Route is protected and we don't have a token.
   * User should sign in before proceeding.
   */
  if (to.path !== ROUTE_LOGIN && to.path !== ROUTE_RESET_PASSWORD && !isAuthenticated.value) {
    return { path: ROUTE_LOGIN };
  }

  if (to.path === ROUTE_WEEKFLOW) {
    mitt.emit("disableAddButtonSidebar", true);
  } else {
    mitt.emit("disableAddButtonSidebar", false);
  }

  /**
   * User is already signed in and trying to navigate to the signIn page.
   * In that case we'll redirect them to the Home page.
   */
  if ((to.path === ROUTE_LOGIN || to.path === ROUTE_RESET_PASSWORD) && isAuthenticated.value) {
    return { path: ROUTE_DASHBOARD };
  }
});

export default router;
