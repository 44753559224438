export const ROUTE_HOME = "/";
export const ROUTE_LOGIN = "/login";

export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/reset-password";
export const ROUTE_UPDATE_PASSWORD = "/update-password";
export const ROUTE_DASHBOARD = "/";

export const ROUTE_ACCOUNT = "/account";
export const ROUTE_ACCOUNT_ACCOUNT = "/account/account";
export const ROUTE_ACCOUNT_DATA = "/account/data";
export const ROUTE_ACCOUNT_BANKACCOUNTS = "/account/bankaccounts";
export const ROUTE_ACCOUNT_CHANGE_BANKACCOUNTS = "/account/bankaccounts/change";

export const ROUTE_WELCOME = "/onboarding/welcome";
export const ROUTE_ONBOARDING = "/onboarding";

export const ROUTE_WEEKFLOW = "/update-flow";
export const ROUTE_LOOKAHEAD = "/look-ahead";

export const ROUTE_TRANSACTIONS = "/transactions/:type";
