import {
  API_CUSTOMERS_DETAIL,
  API_CUSTOMERS_FLOW_UPDATED,
  API_CUSTOMERS_UPDATE_DETAILS,
  API_CUSTOMERS_UPDATE_FINANCIAL_DETAILS,
  API_CUSTOMERS_UPDATE_PASSWORD,
  API_USERS_APPLICATION_SETTINGS,
} from "@/constants/api";
import type { Customer, CustomerFinancialData } from "@/common/types";
import { ref } from "vue";
import api from "@/composables/useApi";

export function useCustomers() {
  const updateCustomer = async (financialData: CustomerFinancialData) => {
    return api.put(API_CUSTOMERS_UPDATE_FINANCIAL_DETAILS, {
      financialData: financialData,
    });
  };

  const getFinancialData = async (): Promise<CustomerFinancialData> => {
    return api.get(API_CUSTOMERS_DETAIL).then(({ data: { data: data } }) => {
      return data.customer.financialData;
    });
  };

  const getDetails = async (): Promise<Customer> => {
    return api.get(API_CUSTOMERS_DETAIL).then(({ data: { data: data } }) => {
      return data.customer;
    });
  };

  const updateDetails = async (customer: Customer): Promise<Customer> => {
    return api.put(API_CUSTOMERS_UPDATE_DETAILS, { customer }).then(({ data: { data: data } }) => {
      return data.customer;
    });
  };

  const updatePassword = async (currentPassword: string, newPassword: string, confirmPassword: string) => {
    return api
      .put(API_CUSTOMERS_UPDATE_PASSWORD, {
        currentPassword,
        newPassword,
        confirmPassword,
      })
      .then(({ data: { data: data } }) => {
        return data;
      });
  };

  const setFlowUpdatedDate = async () => {
    return api.put(API_CUSTOMERS_FLOW_UPDATED);
  };

  const getApplicationSettings = async (): Promise<Record<string, any>> => {
    return api.get(API_USERS_APPLICATION_SETTINGS).then(({ data: { data: data } }) => {
      return data;
    });
  };
  const updateApplicationSettings = async (applicationSettings: Record<string, any>): Promise<Record<string, any>> => {
    return api.put(API_USERS_APPLICATION_SETTINGS, { applicationSettings }).then(({ data: { data: data } }) => {
      return data;
    });
  };

  const netSalary = ref(0);
  const fixedCosts = ref(0);

  return {
    updateCustomer,
    updatePassword,
    getFinancialData,
    getDetails,
    updateDetails,
    setFlowUpdatedDate,
    netSalary,
    fixedCosts,
    getApplicationSettings,
    updateApplicationSettings,
  };
}
