import { defineStore } from "pinia";
import { useTransactions } from "@/composables/useTransactions";
import { ref } from "vue";
import type { TransactionStatus, TransactionType } from "@/common/types";

export const useCoreDataStore = defineStore(
  "core",
  () => {
    const { loadTransactionTypes, loadTransactionStatuses } = useTransactions();

    const transactionTypes = ref<TransactionType[]>();
    const transactionStatuses = ref<TransactionStatus[]>();
    const transactionStatusPaid = ref<TransactionStatus>();
    const transactionStatusUnpaid = ref<TransactionStatus>();
    const transactionTypeIncome = ref<TransactionType>();
    const transactionTypeSalary = ref<TransactionType>();
    const transactionTypeExpense = ref<TransactionType>();
    const coreDataLoaded = ref<boolean>(false);

    async function loadCoreData(): Promise<void> {
      loadTransactionTypes().then((data: TransactionType[]) => {
        transactionTypes.value = data;
        transactionTypeIncome.value = transactionTypes.value?.find((transactionType: TransactionType) => transactionType.isIncome);
        transactionTypeExpense.value = transactionTypes.value?.find((transactionType: TransactionType) => transactionType.isExpense);
        transactionTypeSalary.value = transactionTypes.value?.find(
          (transactionType: TransactionType) => !transactionType.isIncome && !transactionType.isExpense,
        );
      });

      loadTransactionStatuses().then((data: TransactionStatus[]) => {
        transactionStatuses.value = data;
        transactionStatusPaid.value = transactionStatuses.value?.find((transactionStatus: TransactionStatus) => transactionStatus.isPaid);
        transactionStatusUnpaid.value = transactionStatuses.value?.find((transactionStatus: TransactionStatus) => !transactionStatus.isPaid);
      });

      coreDataLoaded.value = true;
      return Promise.resolve();
    }

    return {
      transactionTypes,
      transactionStatuses,
      transactionStatusPaid,
      transactionStatusUnpaid,
      transactionTypeIncome,
      transactionTypeExpense,
      transactionTypeSalary,
      coreDataLoaded,
      loadCoreData,
    };
  },
  {
    persist: {
      storage: localStorage,
    },
  },
);
