import { nl } from "vuetify/locale";

export default {
  hello: "Hallo",
  common: {
    help: "Hulp",
    loading: "Laden...",
    home: "Home",
    cancel: "Annuleren",
    confirm: "Bevestigen",
    minimum: "Minimaal",
    maximum: "Maximaal",
    yes: "Ja",
    no: "Nee",
    showLess: "Toon minder",
    addRule: "Regel toevoegen",
    days: "dagen",
    hours: "uren",
    week: "Week",
    dateOfBirth: "geboortedatum",
    previous: "Vorige",
    next: "Volgende",
    finish: "Afronden",
    add: "Toevoegen",
    edit: "Bewerken",
    delete: "Verwijderen",
    duplicate: "Dupliceren",
    save: "Opslaan",
    saved: "Opgeslagen",
    copyOf: "Kopie van",
    select: "Selecteren",
    total: "Totaal",
    requiredField: "Verplicht veld",
    maxLength50: "Maximaal 50 karakters",
    actions: "Acties",
    close: "Sluiten",
    search: "Zoeken",
    searchTransactions: "Zoek een transactie",
    logout: "Uitloggen",
    newIncome: "Nieuwe ontvangsten",
    newExpense: "Nieuwe uitgaven",
    defaultError: "Er is een fout opgetreden bij het verwerken van je verzoek. Controleer je invoer en probeer het opnieuw",
    expandMenu: "Menu uitklappen",
    collapseMenu: "Menu inklappen",
    alert: "Let op!",
    question: "Vraag",
    confirmLeave: "Weet je zeker dat je pagina wilt verlaten? Wijzigingen die je hebt gemaakt worden niet opgeslagen!",
    generalError: "Er is een fout opgetreden bij het laden van de pagina",
    alertLogout:
      "Je bent al even niet meer actief en wordt daarom voor je eigen veiligheid binnenkort uitgelogd, tenzij je bevestigt dat je ingelogd wilt blijven.",
    stayLoggedIn: "Blijf ingelogd",
    showTour: "Hulp nodig? Klik hierop en je wordt door het scherm geleid.",
    lowResolution: {
      title: "Je scherm is te klein",
      description: "Bezoek deze pagina op een groter scherm om Fineezy te gebruiken.",
    },
  },
  transactions: {
    transactions: "Transacties",
    add: "Transactie toevoegen",
    status: "Status",
    reference: "Referentie",
    category: "Categorie",
    amount: "Bedrag",
    dueDate: "Vervaldatum",
    recurrence: "Herhaling",
    type: "Type",
    transactionDate: "Datum",
    deleted: "De transactie is verwijderd",
    couldNotDelete: "De transactie kon niet verwijderd worden. Probeer het nogmaals",
    updated: "De transactie is bijgewerkt",
    upToAndIncluding: "t/m",
    lastPeriod: "Afgelopen periode",
  },
  titles: {
    tracker: "Tracker",
    split: "Verdelen",
    salary: "Salaris",
    earnings: "Ontvangsten",
    expenses: "Uitgaven",
    dashboard: "Dashboard",
    forecast: "Prognose",
    overview: "Overzicht",
    account: "Account & gegevens",
    yourData: "Jouw data",
    bankAccounts: "Rekeningen",
    yourDashboard: "Welkom terug, {name}",
    updateFlow: "Bijwerken",
    lookAhead: "Voorspellen",
  },
  dashboard: {
    allTasksCompleted: "Super, je hebt alle taken afgerond!",
    revenueTarget: "Omzetdoel",
    costsTarget: "Kostendoel",
    predictedRevenueTarget: "Geprognotiseerd omzetdoel",
    achieved: "Behaald",
    expected: "Verwacht",
    stillToGo: "Nog te gaan",
    numbersThisWeek: "Cijfers deze week",
    balance: "Saldo",
    balanceExpenseAccount: "Saldo kostenrekening",
    balanceSalaryAccount: "Saldo salarisrekening",
    income: "Ontvangsten",
    expenses: "Uitgaven",
    difference: "Verschil",
    incomeAndExpenses: "Ontvangsten en uitgaven",
    onboardingNotComplete: "Je dashboard is nog leeg, want je hebt de onboarding nog niet afgerond.",
    lastUpdate: "Je hebt op {date} voor het laatst je transacties bijgewerkt",
    extraDepositExpensesAccount: "Je hebt dit jaar al {amount} bijgestort op je kostenrekening!",
    negativeValueInChart: "De grafiek kan niet worden weergegeven omdat je een negatief saldo hebt.",
    noSalaryBankAccount: "Je hebt geen aparte bankrekening voor je salaris, daarom kunnen we geen grafiek tonen met het verloop van je salarisrekening.",
  },
  bankaccounts: {
    updateTransactions: "{name} bijwerken",
    add: {
      title: "Rekeningen toevoegen",
      intro:
        "Voor een helder inzicht in je cashflow maken we gebruik van minimaal 3 rekeningen. Zorg er dus voor dat je deze hebt en hieronder invult. Alleen dan heb je pas écht overzicht.",
    },
    choose: {
      title: "Aantal rekeningen",
      intro:
        "Voor een helder inzicht in je cashflow maken we gebruik van minimaal 3 rekeningen. Selecteer hieronder welke situatie voor jouw van toepassing is. Afhankelijk van hoeveel rekeningen je kan hebben bij jouw bank kies je uit een van de opties.",
      accounts: {
        titleThreeAccounts: "3 rekeningen",
        titleFourAccounts: "4 rekeningen",
        titleFiveAccounts: "5 rekeningen",
        subtitle: "Je verdeelt jouw cashflow over de volgende rekeningen:",
        income: "Ontvangsten",
        costs: "Kosten",
        other: "Overig",
        salary: "Salaris",
        profit: "Buffer (winst)",
        taxes: "Belasting",
        select: "Dit is mijn situatie",
      },
    },
  },
  bank: {
    yourBalance: "Jouw banksaldo",
  },
  navigate: {
    quickTo: "Snel naar",
  },
  target: {
    earnings: "Ontvangsten",
    salary: "Salaris",
  },
  login: {
    login: "Inloggen",
    email: "E-mailadres",
    password: "Wachtwoord",
    forgotPassword: "Wachtwoord vergeten",
    errorMessage: "Fout bij het inloggen. Controleer je e-mailadres en wachtwoord en probeer het opnieuw",
    noAccountYet: "Nog geen account?",
    signUpFirstMonthFree: "Meld je aan en probeer Fineezy de eerste maand gratis.",
    signUp: "Aanmelden",
    requestNewPassword: "Vraag een nieuw wachtwoord aan",
    newPasswordRequested:
      "Als het e-mailadres {email} geldig is, ontvang je binnen enkele momenten een e-mail met instructies om je wachtwoord opnieuw in te stellen.",
    resetPassword: "Wachtwoord opnieuw instellen",
    resetPasswordIntro: "Stel hieronder je nieuwe wachtwoord in.",
    currentPassword: "Je huidige wachtwoord",
    newPassword: "Je nieuwe wachtwoord",
    passwordRepeat: "Herhaal je nieuwe wachtwoord",
    passwordsNotTheSame: "De wachtwoorden komen niet overeen",
    minimumCharacters: "Minimaal 6 karakters",
    passwordUpdated: "Je wachtwoord is gewijzigd",
    errorUpdating: "Er is een fout opgetreden bij het bijwerken van je wachtwoord. Controleer alle ingevulde velden en probeer het opnieuw.",
  },
  welcome: {
    title: "Welkom {name}",
    intro:
      "Je staat op het punt om meer inzicht te krijgen in jouw financiën en onderneming. Om jouw ambities en doelen inzichtelijk te maken vragen wij je een aantal gegevens in te vullen. Deze gegevens vormen automatisch een berekening voor het dashboard wat je daarna te zien krijgt.",
    prepare:
      "Zorg ervoor dat je de volgende gegevens bij de hand hebt:<ul><li>Jouw gewenste salaris</li><li>Je jaarlijkse bedrijfskosten</li><li>Overzicht van je rekeningen</li></ul>",
    btnTitle: "Start met invullen",
  },
  tasks: {
    title: "Taken",
    updateBalanceForWeek: "Werk je banksaldi, ontvangsten en uitgaven bij voor week {week}",
    addBankAccounts: "Voeg jouw rekeningen toe om aan de slag te gaan met jouw dashboard",
  },
  onboarding: {
    bufferOk: "Super! Je kan met deze buffer 3 maanden je kosten betalen.",
    introduction: {
      youAndYourCompany: "Jij en jouw onderneming",
      achievedTurnoverAndPaidCostsTitle: "Reeds betaalde omzet en kosten",
      achievedTurnoverDescription: "Hoeveel omzet heb jij dit jaar al ontvangen?",
      paidCostsDescription: "Hoeveel kosten heb jij dit jaar al betaald?",
    },
    costs: {
      companyCosts: "Jouw jaarlijkse bedrijfskosten",
      fixedCosts: "Vaste kosten",
      variableCosts: "Variabele kosten",
      investmentsToExpect: "Investeringen die je verwacht",
      unforeseenCosts: "Onvoorziene kosten",
      totalCompanyCosts: "Totale bedrijfskosten",
    },
    income: {
      yourIncome: "Jouw inkomen",
      netMonthSalary: "Wat wil je aan netto salaris per maand?",
      yourTotalIncome: "Jouw totaalinkomen (netto per jaar)",
      buffer: "Jouw buffer",
      bufferDescription: "Zorg voor een buffer waarvan je minimaal 3x je maandelijkse kosten van {costs} kunt betalen.",
      salaryPayDay: "Op welke dag van de maand keer je jezelf salaris uit?",
    },
    turnoverTarget: {
      adjustData: "Pas je gegevens aan",
      adjustDataDescription:
        "Kijk met een kritische blik naar jouw salaris, je buffer (en eventueel je kosten) om uit te komen op een omzetdoel dat je wel realistisch vindt.",
      check: "Dit heb je ingevuld",
      incomeTax: "Inkomstenbelasting",
      yourTurnoverTarget: "Jouw omzetdoel",
      realistic: "Vind je dit realistisch?",
      realisticDescription: "Schat in of het realistisch is dat je deze omzet ook daadwerkelijk gaat halen.",
      yes: "Ja, ziet er goed uit",
      no: "Nee, te hoog/te laag",
      stillNo: "Nee, nog steeds te hoog/te laag",
      dataAdjustedOk: "Je hebt je gegevens aangepast, vind je dit nu meer realistisch?",
      dataAdjustedOkDescription: "Schat in of het realistisch is dat je deze omzet ook daadwerkelijk gaat halen.",
      wellDone: "Super gedaan!",
      wellDoneDescription: "Goed dat je hebt gekeken naar wat haalbaar is. Ga nu door naar de volgende stap.",
    },
    split: {
      income: "Ontvangsten",
      incomeDescription: "Dit is wat nodig is aan geld voor het behalen van je omzetdoel en de daarop berekende BTW.",
      splitIncome: "Zo wordt je omzet verdeeld",
      splitIncomeDescription: "Je omzet van {target} (100%) wordt op de volgende manier verdeeld:",
      revenue: "Omzet",
      vat: "BTW",
      salary: "Salaris",
      incomeTax: "Inkomstenbelasting",
      costs: "Kosten",
      profitBuffer: "Buffer (Winst)",
    },
    bankAccounts: {
      bankAccounts: "Hoeveel rekeningen heb je?",
      bankAccountsDescription: "Voor een overzichtelijke cashflow heb je minimaal 3 rekeningen nodig.",
      select: "Selecteren",
      whyNoMore:
        "Waarom kan ik niet 1 rekening kiezen? <a href='https://kennisbank.fineezy.nl/nl/articles/411488-hoe-je-je-bankrekeningen-inricht-voor-je-start' target='_blank'>Zie de uitleg in de kennisbank</a>.",
      accountsAndBalance: "Rekeningen & saldi invullen",
      accountsAndBalanceDescription: "Voer bij elke rekening de juiste IBAN in en het huidige saldo.",
      3: {
        title: "3 rekeningen",
        description: "Je overige rekening wordt gebruikt voor belastingen, salaris en buffer.",
      },
      4: {
        title: "4 rekeningen",
        description: "Je overige rekening wordt gebruikt voor belastingen en buffer.",
      },
      5: {
        title: "5 rekeningen",
        description: "Elke rekening heeft een eigen rol. Hiermee heb je het beste overzicht.",
      },
    },
    finishOnboarding: "Onboarding afronden",
    calculatorHelp: "Rekenhulp",
    chipTitle: "Stap",
    previousStep: "Vorige stap",
    nextStep: "Volgende stap",
    headerTitle: {
      1: "Even voorstellen",
      2: "Kosten",
      3: "Inkomen",
      4: "Omzetdoel",
      5: "Verdelen",
      6: "Rekeningen",
    },
    headerSubTitle: {
      1: "Voordat we beginnen is het goed om te weten wie jij bent!",
      2: "Laten we starten met je kosten. Wat zijn de totale uitgaven die je verwacht dit jaar?",
      3: "Wat je wilt verdienen en je buffer.",
      4: "Op basis van wat je hebt ingevuld is dit je omzetdoel:",
      5: "Jouw omzet verdeel je over diverse potjes.",
      6: "Je bent er bijna. Voer hieronder je rekeningen in waarop je elke week jouw omzet gaat verdelen.",
    },
    stepper: {
      1: "Introductie",
      2: "Kosten",
      3: "Inkomen",
      4: "Omzetdoel",
      5: "Verdelen",
      6: "Rekeningen",
    },
  },
  lookAhead: {
    totalOverview: "Totaaloverzicht",
    yourSalesGoalAndSalesNeeded: "Jouw omzetdoel en de omzet die je nodig hebt",
    salesGoal: "Omzetdoel",
    requiredRevenue: "Benodigde omzet",
    vatPercentage: "BTW percentage",
    percentages: "Percentages",
    whichPercentageToHandle: "Kies welke percentage je wilt hanteren voor de berekening.",
    part: "Onderdeel",
    amount: "Bedrag",
    yourPercentage: "Jouw percentage",
    avgPercentage: "Gem. percentage",
    salary: "Salaris",
    incomeTax: "Inkomstenbelasting",
    costs: "Kosten",
    profit: "Buffer",
    profitBuffer: "Buffer (winst)",
    calculatedPercentage: "Berekend percentage",
    desiredPercentage: "Gewenst percentage",
    lookForward: "Voorspellen",
    checkPredictions:
      "Check onderstaande voorspellingen en vul nieuwe verwachtingen in. Let op: je kunt enkel transacties invoeren na vandaag.<br/><br/>Je kunt ook negatieve bedragen ingeven om bijvoorbeeld aanzuiveringen in te plannen. Check voor meer informatie onze <a href='https://kennisbank.fineezy.nl/nl/85794-voorspellen' target='_blank'>kennisbank</a>",
    addExpenses: "Uitgaven toevoegen",
    addIncome: "Ontvangsten toevoegen",
    nothingAdded: "Je hebt nog niets toegevoegd voor deze week.",
    addWeek: "Week toevoegen",
    income: "Ontvangsten",
    expenses: "Uitgaven",
  },
  account: {
    account: "Account",
    welcome: "Welkom {firstName}",
    signOut: "Uitloggen",
    updatePassword: "Wachtwoord wijzigen",
    updatePasswordIntro: "Wijzig hieronder je wachtwoord.",
    sideMenu: {
      overview: "Overzicht",
      profile: "Account & gegevens",
      data: "Jouw data",
      bankAccounts: "Rekeningen",
      recurringTransactions: "Terugkerende transacties",
      weekflow: "Transacties bijwerken",
      lookAhead: "Voorspellen",
    },
    validation: {
      payDay: "Vul een dag in tussen 1 en 28",
    },
    overview: {
      yourDetails: "Jouw gegevens",
      editDetails: "Gegevens wijzigen",
      yourData: "Jouw data",
      onboardingData: "Hier kan je de informatie bewerken die je tijdens de onboarding hebt ingevuld.",
      yourIncomeNetYear: "Jouw inkomen (netto per jaar)",
      hourlyRate: "Uurtarief",
      yourSalesGoal: "Jouw omzetdoel",
      companyCosts: "Bedrijfskosten",
      editData: "Data wijzigen",
      notAllowedChangeFinancialData: "Je kunt op dit moment je financiële gegevens niet wijzigen. Neem contact met ons op als je dit wilt doen.",
      bankAccounts: "Rekeningen",
      editBankAccounts: "Rekeningen wijzigen",
      needHelp: "Heb je hulp nodig?",
      gotQuestions: "Heb je een vraag of kom je niet verder? Neem contact met ons op, we helpen je graag!",
      contact: "Contact opnemen",
      salaryPayDay: "Betaaldag salaris",
    },
    profile: {
      yourAvatar: "Jouw profielfoto",
      chooseAvatar: "Kies afbeelding",
      yourData: "Jouw gegevens",
      firstName: "Voornaam",
      lastName: "Achternaam",
      companyData: "Jouw bedrijf",
      companyName: "Bedrijfsnaam",
      vatRate: "BTW tarief",
      email: "E-mailadres",
      emailPreferences: "E-mailvoorkeuren",
      receiveNewsletter: "Ja, ik ontvang graag nieuws en updates via de nieuwsbrief",
      receiveTips: "Ik ontvang graag persoonlijke tips om mijn dashboard te verbeteren",
      updated: "Je gegevens zijn opgeslagen!",
    },
    data: {
      yourIncome: "Jouw inkomen",
    },
    recurringTransactions: {
      title: "Terugkerende transacties",
      alert1Year: "Je kunt terugkerende transacties maximaal een jaar vooruit plannen.",
    },
    bankAccounts: {
      bankAccounts: "Jouw Rekeningen",
      bankAccountsDescription: "Op deze rekeningen verdeel jij jouw omzet. Je kan hier je IBAN bewerken.",
      changeNumberOfAccountsTitle: "Wil je het aantal rekeningen wijzigen?",
      changeNumberOfAccountsDescription:
        "Dat heeft best wat impact op hoe jouw dashboard berekend wordt! Mail daarom jouw aanvraag en het aantal rekeningen dat je wilt hebben naar support{'@'}fineezy.nl en dan zorgen wij ervoor dat jouw rekeningen worden gewijzigd.",
      changeNumberOfAccounts: "Wijzig aantal rekeningen",
      confirmChangeBankAccounts:
        "Weet je zeker dat je je bankrekeningen wilt wijzigen? Zorg dat het saldo dat je hebt opgegeven ook daadwerkelijk op je rekening staat!",
      changeBankAccountsSaved: "Je wijziging is opgeslagen",
      ibanUpdated: "Het IBAN nummer is bijgewerkt",
    },
    changeFinancialData: {
      confirm: "Bevestigen",
      saved: "Je financiële gegevens zijn opgeslagen",
    },
  },
  modalWalkthrough: {
    skip: "Overslaan",
    next: "Volgende",
    previous: "Vorige",
    1: {
      header: "Goed gedaan!",
      title: "Jouw dashboard is gereed",
      content:
        "Goed bezig, {name}! Jouw dashboard staat klaar. Vanaf hier start het echte werk. Voor overzicht en grip op jouw cashflow! Nu volgt een korte uitleg hoe jij aan de slag gaat.",
    },
    2: {
      header: "Aan de slag",
      title: "Zo krijg je grip op je cashflow",
      content:
        "In jouw dashboard zie je in één oogopslag hoe het met jouw cashflow gaat. Hier ga je bijwerken, voorspellen en bijsturen. Zo heb jij echt grip op jouw geld.",
    },
    3: {
      header: "Bijwerken",
      title: "Dit is er gebeurd",
      content:
        "Elke week vul je de saldi van jouw rekeningen> Je checkt of de voorspellingen van jouw ontvangsten & uitgaven kloppen met de werkelijkheid. Vervolgens zie je hoe je omzet verdeeld over elke rekening.",
    },
    4: {
      header: "Voorspellen",
      title: "Dit gaat er gebeuren",
      content:
        "Je denkt na over wat er gaat komen aan uitgaven en ontvangsten op basis van de acties die je onderneemt. Deze voorspellingen check je vervolgens elke week of ze kloppen met de werkelijkheid.",
    },
    5: {
      header: "Dashboard",
      title: "Inzicht en grip met je dashboard",
      content:
        "Je hebt nu alle gegevens bijgewerkt. En daarmee inzicht in de toekomst van jouw cashflow. Elke week herhaal je deze workflow om grip te houden op jouw geld. En de grafieken zo up to date mogelijk te houden",
    },
  },
  modalAddTransaction: {
    addIncome: "Ontvangsten toevoegen",
    addExpense: "Uitgave toevoegen",
    addExpenses: "Uitgaven toevoegen",
    type: "Type",
    status: "Status",
    organisation: "Organisatie",
    reference: "Referentie",
    category: "Categorie",
    amount: "Bedrag",
    ultimatePayDay: "Uiterste betaaldatum",
    startDate: "Begindatum",
    repeat: "Herhaling",
    endsOn: "Eindigt op",
    noEnd: "Geen einde",
    onDate: "Op datum",
    afterCoupleTimes: "Na aantal keer",
    added: "De transactie is toegevoegd",
    addNew: "Voeg direct een nieuwe transactie toe",
    didYouMean: "Bedoelde je:",
    confirmDeleteTransaction: "Weet je zeker dat je deze transactie wilt verwijderen?",
    confirmDeleteTransactionTemplate:
      "Weet je zeker dat je deze terugkerende transactie wilt verwijderen? Alle onbetaalde transacties in de toekomst worden hierdoor ook verwijderd!",
    shouldUpdateTransactionTemplate: "Wil je alleen deze transactie aanpassen of ook alle toekomstige transacties?",
    shouldDeleteTransactionTemplate: "Wil je alleen deze transactie verwijderen of ook alle toekomstige transacties?",
    transactionOnly: "Alleen deze transactie",
    futureTransaction: "Deze en volgende transacties",
    alertChangeRecurring: "Let op; je wijzigt hiermee alle toekomstige transacties in de reeks.",
    negativeAlertIncome: "Let op: gebruik negatieve bedragen alleen om creditfacturen te verwerken.",
    negativeAlertExpense: "Let op: gebruik negatieve bedragen alleen om aanzuiveringen op je kostenrekening te verwerken.",
  },
  modalConfirmSplit: {
    title: "Heb je alle bedragen overgemaakt?",
    content: "Zorg ervoor dat je alle bedragen hebt overgemaakt op de rekeningen voordat je doorgaat.",
    confirmed: "Ja, ik heb alle bedragen overgemaakt",
    back: "Nee, ga terug",
  },
  recurringTransactions: {
    add: "Terugkerende transactie toevoegen",
  },
  modalUpdateTransaction: {
    title: "Transactie bijwerken",
    saveTransaction: "Opslaan",
    deleteTransaction: "Verwijderen",
    updateTransaction: "Transactie bewerken",
    updated: "De transactie is bijgewerkt",
  },
  modalUpdateTransactionTemplate: {
    title: "Terugkerende transactie bijwerken",
    saveTransaction: "Opslaan",
    startDate: "Begindatum",
    updateTransactionTemplate: "Terugkerende transactie bewerken",
    deleteTransactionTemplate: "Terugkerende transactie verwijderen",
    confirmDeleteTransactionTemplate:
      "Weet je zeker dat je deze terugkerende transactie wilt verwijderen? Alle toekomstige transacties gebaseerd op deze terugkerende transactie worden hiermee ook verwijderd!",
    updated: "De terugkerende transactie is bijgewerkt",
    deleted: "De terugkerende transactie is verwijderd",
  },
  modalWeekflowWeekFinished: {
    title: "Jouw dashboard is gereed",
    content: "Goed bezig, {firstName}! Jouw dashboard voor week {week} staat klaar. Vanuit hier heb je overzicht voor grip op jouw cashflow!",
    btnTitle: "Bekijk dashboard",
  },
  modalVerifyTransactionStatuses: {
    title: "Regels controleren",
    content: "Helaas kan je niet doorgaan als er regels op prognose staan. De volgende dingen kan je doen:",
    btnTitle: "Ik snap het",
    editStatusToPaid: "Status wijzigen naar betaald",
    editStatusToNextWeek: "Regel bewerken en verplaatsen naar een volgende week",
    deleteLine: "Regel verwijderen",
  },
  modalCoaching: {
    subtitle: "Hulp nodig?",
    title: "Vraag een coachsessie aan met Fineezy!",
    content:
      "Zo te zien heb je moeite met een realistisch omzetdoel te bepalen, dat is niet erg dat kan ook best lastig zijn. Vraag een coachsessie aan met Fineezy en zij helpt je verder om een realistisch omzetdoel te bepalen.",
    requestInfo: "Informatie & aanvragen",
    ITry: "Ik probeer het zelf nog even…",
  },
  updateTransactions: {
    startUpdate: "Rekening bijwerken",
    modal: {
      description: "Vul het huidige saldo in van je rekening met IBAN {iban}.",
      start: "Start met bijwerken",
    },
    balanceDifference: "Saldo verschil",
    difference: "Verschil",
    markAsOther: {
      title: "Boek als overige",
      income: "Overige ontvangsten",
      expenses: "Overige uitgaven",
      warning: "Let op: gebruik deze optie niet om bijstortingen weg te boeken!",
    },
    confirmUpdate: "Bijwerken afronden",
    confirmUpdateConfirm:
      "Weet u zeker dat u het bijwerken wilt afronden? Hierdoor wordt u banksaldo definitief opgeslagen en alle transacties als betaald gemarkeerd.",
  },
  weekflow: {
    stepper: {
      1: {
        title: "Saldi",
      },
      2: {
        title: "Ontvangsten",
      },
      3: {
        title: "Uitgaven",
      },
      4: {
        title: "Verdelen",
      },
      5: {
        title: "Voorspellen",
      },
    },
    alreadyUpToDate: "Je bent al helemaal bij!",
    alreadyUpToDateComment:
      "Je hebt vandaag al je banksaldi bijgewerkt. Dit kan maar één keer per dag. Het beste kan je dit wekelijks op hetzelfde moment doen, dan weet je zeker dat je het niet vergeet. Je wordt weer teruggestuurd naar je dashboard.",
    period: "Periode",
    transactions: {
      previousBalance: "Vorig banksaldo",
      currentBalance: "Huidig banksaldo",
      expected: "Saldo verschil",
      filled: "Op Betaald gezet",
      correction: "Correctie",
      difference: "Nog te verwerken",
      errorStatusNotPaidPlural:
        "Let op, er staat nog {count} regel nog niet op de status betaald. Pas deze aan om door te gaan. | Let op, er staan nog {count} regels nog niet op de status betaald. Pas deze aan om door te gaan.",
      errorStatusNotPaidLink: "Meer informatie",
      noTransactions: "Er zijn nog geen transacties toegevoegd voor deze periode. Gebruik de knop Toevoegen om een nieuwe transactie aan te maken.",
      noFutureTransactions: "Er zijn nog geen transacties toegevoegd voor de toekomst. Gebruik de bovenstaande knoppen om nieuwe transacties toe te voegen",
    },
    stepOne: {
      editBalance: "Vul je banksaldi in",
      checkAmountsUpdateAccount: "Pak je Bank-app erbij en vul het huidige saldo van de onderstaande rekeningen in.",
    },
    step2: {
      income: "Ontvangsten",
      introduction:
        "Hier zie je de ontvangsten terug die je eerder hebt voorspeld voor bovenstaande periode. Heb je meer ontvangen? Dan voeg je dit alsnog toe. Is er geld afgeschreven? Dan zet je een minnetje voor het bedrag.",
      totalReceipts: "Totaal ontvangsten",
      positiveText: "Ontvangst",
      negativeText: "Uitgave",
    },
    step3: {
      introduction:
        "Hier zie je de uitgaven die je eerder hebt voorspeld voor bovenstaande periode. Heb je meer uitgegeven? Dan voeg je deze uitgaven alsnog toe. Heb je geld gestort/ontvangen op deze rekening? Dan zet je een minnetje voor het bedrag.",
      expenses: "Uitgaven",
      totalExpenses: "Totaal uitgaven",
      positiveText: "Uitgave",
      negativeText: "Aanzuivering",
    },
    stepFour: {
      divide: "Verdelen",
      totalRecieved: "Totaal ontvangsten",
      account: "Rekening",
      iban: "IBAN",
      percentage: "Percentage",
      amount: "Bedrag",
      reference: "Referentie",
      splitDescription: "Zorg ervoor dat je deze bedragen overmaakt naar de volgende rekeningen.",
      toAccountWeekly: "Naar {account} week {week}",
      confirmTransferred: "Ik heb de bedragen naar mijn rekeningen overgemaakt",
      newBalance: "Nieuw saldo",
      VAT: "BTW",
      totalReceived: "Totaal ontvangen",
    },
    stepFive: {
      lookForward: "Voorspellen",
      checkPredictions: "Check onderstaande voorspellingen en vul nieuwe verwachtingen in.",
      addExpenses: "Uitgaven toevoegen",
      addIncome: "Ontvangsten toevoegen",
      nothingAdded: "Je hebt nog niets toegevoegd voor deze week.",
      addWeek: "Week toevoegen",
      income: "Ontvangsten",
      expenses: "Uitgaven",
    },
  },
  periods: {
    once: "Eenmalig",
    weekly: "Wekelijks",
    monthly: "Maandelijks",
    quarterly: "Elk kwartaal",
    yearly: "Jaarlijks",
  },
  $vuetify: {
    ...nl,
    dataFooter: {
      itemsPerPageAll: "Alle",
      firstPage: "Eerste",
      lastPage: "Laatste",
    },
  },
  tooltips: {
    onboarding: {
      vatTariff:
        "Kies hier het btw-tarief dat op jouw diensten van toepassing is. Zo zorg je ervoor dat je straks voldoende opzij zet. Lever je producten of diensten met verschillende btw-tarieven? Kies dan het tarief waar je de meeste omzet mee draait. Wil je liever iets minder dan 21% opzij zetten, omdat je veel btw terugkrijgt? Kies dan voor 15%. <br/><br/>Lees vooral ook de tips voor de onboarding in de kennisbank van Fineezy.",
      achievedTurnover:
        "Waarschijnlijk heb je dit jaar al omzet gedraaid. Vul hier de omzet in die je tot nu toe daadwerkelijk hebt ontvangen. Check hiervoor je boekhoudprogramma. Het gaat om jouw omzet exclusief de btw en minus de openstaande facturen (Oftewel: de klanten die hun rekening nog niet aan jou hebben betaald.)",
      paidCosts:
        "Waarschijnlijk heb je dit jaar al kosten gemaakt. Vul hier de kosten in die je tot nu toe daadwerkelijk hebt betaald. Check hiervoor je boekhoudprogramma.",
      fixedCosts:
        "Dit zijn kosten, in een jaar, die je altijd hebt. Vaste bedragen die op vaste momenten worden afgeschreven van jouw bankrekening. Denk aan de huur van je kantoor of bedrijfspand, software, je personeel, etc.",
      variableCosts:
        "Deze kosten variëren elk jaar en zijn niet vast. Ze zijn afhankelijk van jouw business. Denk aan je inkoopkosten, kosten voor marketing, kantoorkosten etc. Vind je het lastig om in te schatten wat jouw variabele kosten zijn? Kijk dan naar je variabele kosten van het afgelopen   jaar. Wat verwacht je dat er komend jaar gaat gebeuren?",
      investmentsToExpect:
        "Vul hier de bedragen in voor extra uitgaven die je dit jaar gaat doen. Ben je dit jaar van plan een nieuwe website te laten bouwen? Wil je nieuwe apparatuur aanschaffen of oude vervangen? Of ga je een marketingcampagne starten? Zet dan geld opzij voor zulke investeringen in jouw bedrijf. ",
      unforeseenCosts:
        "Er zijn altijd kosten waar je niet op kunt anticiperen. Bijvoorbeeld inflatie. Wil je hiervoor reserveren? Zet dan bijvoorbeeld 10% van je totale vaste kosten opzij.",
      netSalary:
        "Dit bedrag keer je jezelf maandelijks uit. Jouw salaris. Of het bedrag dat je eigenlijk wilt verdienen. Houd hierbij rekening met je privé kosten, zoals pensioenopbouw, arbeidsongeschiktheidsverzekering etc.",
      buffer:
        "Een gezond bedrijf heeft een buffer, zodat je ook in tijden van minder of geen omzet je vaste lasten kunt betalen. Een buffer van ongeveer 3 maanden voor je vaste kosten is een goed uitgangspunt. Daar is dit potje voor bedoeld.<br/><br/>Wanneer je verwacht dat dit lastig wordt om nu bij elkaar te kunnen sparen, dan stel je dit doel bij.",
      incomeTax:
        "Dit is een grove schatting van de inkomstenbelasting die je na dit boekjaar gaat betalen. Check vooral altijd zelf hoe je ervoor staat. Deze tool berekent op basis van wat je bij het salaris en de buffer hebt ingevuld hoeveel belasting je waarschijnlijk moet betalen. Jouw salaris en buffer zijn samen de winst van jouw onderneming en hierover betaal je belasting.<br/><br/>Let op: bij deze berekening is verder geen rekening gehouden met je persoonlijke situatie of de fase waarin jouw bedrijf zit. Denk aan startersaftrek die je krijgt als beginnend ondernemer, de hypotheekrenteaftrek etc. Met dit potje reserveer je dus geld waarmee je je inkomstenbelasting gaat betalen.",
      splitPercentages:
        "Op basis van deze percentages verdeel je jouw omzet over je salaris, inkomstenbelasting, je verschillende kosten en eventuele extra buffer. Deze omzet is dus een prognose op basis van jouw verwachtingen.",
      numberOfAccounts:
        "Om grip te krijgen op je geld is het nodig dat je inkomsten splitst van de rest. Zodat je de inkomsten makkelijk kunt verdelen over de verschillende gelddoelen. Dat doe je door 5 aparte betaal/spaarrekeningen te gebruiken. Eén waarop je inkomsten binnenkomen, één gebruik je om je bedrijfskosten te betalen. En de rest voor je salaris, belastingen en winst. Is dat lastig bij je bank? Kies dan minimaal 3 rekeningen. Lees hier vooral meer over in de kennisbank.",
      salaryPayDay: "Dit wordt gebruikt om het verloop van je salarisrekening te kunnen voorspellen.",
    },
    flow: {
      previousBalance: "Dit was het saldo van je rekening nadat je de vorige keer Fineezy hebt bijgewerkt.",
    },
    dashboard: {
      extraDepositExpensesAccount:
        "Als je steeds je kostenrekening moet aanvullen. Dan is dat het signaal dat er iets niet lekker loopt. Misschien ben je te royaal met je uitgaven of is er te weinig omzet. Goed moment om dit te checken. Want het gaat ten laste van je andere doelen",
    },
  },
  tour: {
    next: "Volgende",
    back: "Terug",
    done: "Sluiten",
    hide: "Niet meer tonen",
    onboarding: {
      introduction: {
        achievedTurnover: {
          title: "Behaalde omzet",
          content:
            "Waarschijnlijk heb je dit jaar al omzet gedraaid. Vul hier de omzet in die je tot nu toe hebt gemaakt én daadwerkelijk hebt ontvangen. Check hiervoor je boekhoudprogramma. Het gaat om jouw omzet exclusief de btw en minus de openstaande facturen. Oftewel: de klanten die hun rekening nog niet aan jou hebben betaald.",
        },
        paidCosts: {
          title: "Betaalde kosten",
          content:
            "Waarschijnlijk heb je dit jaar al kosten gemaakt. Vul hier de kosten in die je tot nu toe hebt gemaakt én daadwerkelijk hebt betaald. Check hiervoor je boekhoudprogramma.",
        },
      },
      split: {
        receptions: {
          title: "BTW",
          content:
            "Je ontvangt dus totaal {totalReceptions} op je rekening. Hiervan is {vat} BTW die je moet afdragen. In de onderstaande grafiek gaan we het restant van {income} verdelen.",
        },
        income: {
          title: "Verdelen",
          content: "De {income} die je overhoudt, na aftrek van de BTW, ga je verdelen over je gelddoelen.",
        },
      },
      accounts: {
        title: "Bankrekeningen",
        content:
          "Om elke euro effectief te verdelen naar de doelen die je hebt gesteld, bieden we je de keuze tussen 3, 4 of 5 bankrekeningen. Hoewel alle opties werken, is het gebruik van 5 rekeningen optimaal voor de meeste ondernemers. Neem een kijkje in onze kennisbank voor meer inzicht in dit concept en maak vervolgens je keuze voor het aantal rekeningen dat het beste bij jou past!",
      },
    },
    weekflow: {
      step1: {
        dates: {
          title: "Saldi bijwerken",
          content:
            "De vorige keer dat je je banksaldi in Fineezy hebt bijgewerkt was {start}. We gaan straks alle transacties bijwerken die sinds {start} hebben plaatsgevonden, maar we beginnen met het invullen van je banksaldi van vandaag.  ",
        },
        bankAccounts: {
          title: "Je bankrekeningen",
          content:
            "Vul per rekening het huidige banksaldo in. Sinds de vorige keer dat je hebt bijgewerkt is er waarschijnlijk veel gebeurd op je bankrekeningen. En dit dient als extra controle dat je alles goed verwerkt.",
        },
      },
      step2: {
        transactions: {
          title: "Ontvangsten",
          content:
            "Hier zie je de omzet die je hebt voorspeld voor deze periode.\nWat hiervan daadwerkelijk is ontvangen op je rekening zet je op betaald. Dat wat nog niet is binnengekomen laat je op verwacht staan. En neemt Fineezy mee naar morgen.\nAls er nog veel openstaat, is dit het moment om beter in te schatten wanneer klanten gaan betalen. Of om ze een belletje te geven.\nLaat je geld niet verpieteren!",
        },
        balances: {
          title: "Controle",
          content:
            "Vorig banksaldo: Dit was jouw saldo nadat je de laatste keer hebt bijgewerkt.\nHuidig banksaldo: Dit is het bedrag dat je zojuist hebt ingevuld bij stap 1.\nSaldo verschil: Dit is het totale bedrag aan veranderingen in je banksaldo in de afgelopen periode.\n" +
            "Op Betaald gezet: Het totaal aan transacties dat je op 'Betaald' hebt gezet.\n" +
            "Verschil: Om door te kunnen gaan naar de volgende stap moet het verschil 0 zijn. Dan weet je zeker dat je alle transacties hebt verwerkt.",
          highCorrection:
            "Heb je een (afrondings)verschil? Vul deze ook in met de knop 'toevoegen'. Zo krijg je ook een goede weergave in het dashboard. Check hier de uitleg in onze kennisbank.",
        },
        add: {
          title: "Onverwachte ontvangsten",
          content:
            "Een ontvangst (nog) niet zien aankomen? Dat zijn de leuke verrassingen!\nVoeg deze alsnog toe.\n\n(Ook als er per ongeluk iets is afgeschreven)",
        },
      },
      step3: {
        transactions: {
          title: "Uitgaven",
          content:
            "Hier zie je de uitgaven die je hebt voorspeld voor deze periode.\n" +
            "Wat je hiervan daadwerkelijk hebt betaald in deze periode zet je op betaald. Dat wat nog niet is binnengekomen laat je op verwacht staan. En neemt Fineezy mee naar morgen. \n" +
            "\n" +
            "Als je merkt dat je veel uitgaven moet toevoegen, is dit het perfecte moment om beter te voorspellen of je uitgavenpatroon aan te passen. Let’s get smart!\n",
        },
        balances: {
          title: "Controle",
          content:
            "Vorig banksaldo: Dit was jouw saldo nadat je de laatste keer hebt bijgewerkt.\nHuidig banksaldo: Dit is het bedrag dat je zojuist hebt ingevuld bij stap 1.\nVerschil: Dit is het totale bedrag aan veranderingen in je banksaldo in de afgelopen periode.\n" +
            "Op betaald gezet: Het totaal aan transacties dat je op 'Betaald' hebt gezet. \n" +
            "Verschil: Om door te kunnen gaan naar de volgende stap. Moet het verschil 0 zijn. Dan weet je zeker dat je alle transacties hebt verwerkt.",
          highCorrection:
            "Heb je een (afrondings)verschil? Vul deze ook in met de knop 'toevoegen'. Let op: doe dit enkel bij kleine verschillen. Check onze kennisbank voor meer info.",
        },
        add: {
          title: "Onverwachte uitgaven",
          content: "Een uitgave niet zien aankomen of eerder betaald?\n" + "Dan voeg je die alsnog toe.\n" + "\n" + "Ook als je geld hebt gestort.\n",
        },
      },
    },
    dashboard: {
      revenueTarget: {
        title: "Je omzetdoel",
        content:
          "Deze donut laat je zien waar je staat richting je omzetdoel. Donkerblauw voor het geld dat al binnen is, lichterblauw voor het verwachte geld, en nog lichterblauw voor wat nog moet binnenkomen. Een visuele trip naar financieel succes!",
      },
      costTarget: {
        title: "Je kostendoel",
        content:
          "Deze donut laat zien welke kosten je hebt gebudgetteerd en hoever je daarmee bent. Let op, je wilt deze donut liever niet (snel) vol zien raken. Blijft kritisch met je uitgaven!",
      },
      incomeAndExpenses: {
        title: "Ontvangsten en uitgaven",
        content:
          "Check hoe jouw uitgaven en ontvangsten zich tot elkaar verhouden. Klik op een staafje en ontdek welke uitgaven of ontvangsten daarbij horen. De uitdaging? Zorg dat de ontvangsten altijd hoger zijn dan je uitgaven. Onthoud; niet al je inkomsten gaan naar je uitgaven! Probeer ook je uitgaven minder te laten pieken op bepaalde momenten. Speel met je geld voor een stabielere flow!",
      },
      balanceExpenseAccount: {
        title: "Je kostenrekening",
        content:
          "Je ziet hier je kostenrekening zich, naar verwachting, zal ontwikkelen in de toekomst. Let wel op, deze grafiek is altijd in beweging - en altijd naar beneden! Dat komt omdat het je uitgaven betreft. En een deel van je ontvangsten.\n" +
          "De uitdaging? Altijd een positief saldo behouden! Op die manier weet je dat je andere financiële doelen in de lift zitten!",
      },
      balanceSalaryAccount: {
        title: "Je salarisrekening",
        content:
          "Hier zie je hoe je salarisrekening zich, naar verwachting, zal ontwikkelen. Let op: Als je kostenrekening niet voldoende saldo heeft, dan is dat een signaal. Dan kun je misschien ook geen salaris opnemen ",
      },
    },
    lookAhead: {
      title: "Voorspellen",
      content:
        "Voer hier al je toekomstige inkomsten en uitgaven in. Vul dit, bij voorkeur, wekelijks aan. Hoe nauwkeuriger jij dit doet, hoe helderder het dashboard jou laat zien hoe jouw geld zich ontwikkelt!\n" +
        "Let op; In dit scherm zie je alle inkomsten en uitgaven terug die nog niet zijn ontvangen en/of betaald.\n" +
        "Weten hoe je hier handig/simpel mee werkt? Check de <a href='https://kennisbank.fineezy.nl/nl/85794-voorspellen' target='_blank'>kennisbank</a>",
    },
  },
  weekBuckets: {
    expectedBalance: "Verwacht saldo",
    endOfWeek: "(einde week)",
    updateBalance: "Saldo bijwerken",
    balanceAccount: "Saldo {accountName}",
    currentBalanceThisAccount: "Vul het huidige saldo in van deze rekening",
    previousBalance: "Vorig banksaldo",
    currentBalance: "Huidig banksaldo",
    expected: "Saldo verschil",
  },
};
