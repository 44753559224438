import {
  API_TRANSACTION_SEARCH,
  API_TRANSACTION_STATUSES,
  API_TRANSACTION_TEMPLATE_DELETE,
  API_TRANSACTION_TEMPLATES,
  API_TRANSACTION_TYPES,
  API_TRANSACTIONS,
} from "@/constants/api";
import type { Transaction, TransactionTemplate } from "@/common/types";
import { TransactionData } from "@/common/types"; // @ts-ignore
import dayjs from "dayjs";
import api from "@/composables/useApi";

export function useTransactions() {
  const loadTransactionStatuses = () => {
    return api.get(API_TRANSACTION_STATUSES).then(({ data: { data } }) => {
      return data;
    });
  };

  const loadTransactionTypes = () => {
    return api.get(API_TRANSACTION_TYPES).then(({ data: { data } }) => {
      return data;
    });
  };

  const addTransaction = async (transaction: TransactionData | Transaction) => {
    if (isTransaction(transaction)) {
      return updateTransaction(transaction);
    }
    return api.post(API_TRANSACTIONS, { transaction: transaction }).then(({ data }) => {
      if (data.status == "success") {
        return data.data;
      } else {
        return false;
      }
    });
  };

  const updateTransaction = (transaction: Transaction) => {
    return api
      .put(API_TRANSACTIONS, {
        transaction: transaction,
      })
      .then(({ data }) => {
        if (data.status === "success") {
          return data.data;
        }
      });
  };

  const deleteTransaction = (transaction: Transaction) => {
    return api.delete(`${API_TRANSACTIONS}/${transaction.id}`).then(({ data }) => {
      if (data.status === "success") {
        return data.data;
      }
    });
  };

  const searchTransactions = (
    reference?: string,
    transactionTypeId?: number,
    transactionStatusId?: number,
    start?: Date,
    end?: Date,
  ): Promise<Array<Transaction>> => {
    return api
      .post(API_TRANSACTION_SEARCH, {
        search: reference,
        transactionTypeId,
        transactionStatusId,
        start: start ? dayjs(start).unix() : null,
        end: end ? dayjs(end).unix() : null,
      })
      .then(({ data: { data } }) => {
        return data;
      });
  };

  const updateTransactions = (transactions: Array<Transaction>) => {
    return api
      .put(`${API_TRANSACTIONS}`, {
        transactions,
      })
      .then(({ data }) => {
        if (data.status === "success") {
          return data.data;
        }
      });
  };

  const getTransactionTemplates = () => {
    return api.get(API_TRANSACTION_TEMPLATES).then(({ data: { data } }) => {
      return data;
    });
  };

  const updateTransactionTemplate = (transactionTemplate: TransactionTemplate, dateFrom?: Date) => {
    return api
      .put(`${API_TRANSACTION_TEMPLATES}/${transactionTemplate.id}`, {
        transactionTemplate: transactionTemplate,
        dateFrom: dateFrom,
      })
      .then(({ data: { data } }) => {
        return data;
      });
  };

  const deleteTransactionTemplate = (transactionTemplate: TransactionTemplate, dateFrom?: Date) => {
    return api
      .put(`${API_TRANSACTION_TEMPLATE_DELETE}/${transactionTemplate.id}`, {
        dateFrom: dateFrom ? dayjs(dateFrom).unix() : null,
      })
      .then(({ data: { data } }) => {
        return data;
      });
  };

  const isTransaction = (input: string | Transaction | TransactionData): input is Transaction => {
    return (input as Transaction)?.id !== undefined;
  };

  const moveTransaction = (transaction: Transaction, transactionDate: string): Promise<Transaction> => {
    return api
      .put(`${API_TRANSACTIONS}/move`, {
        transaction,
        transactionDate,
      })
      .then(({ data: { data } }) => {
        return data;
      });
  };

  return {
    loadTransactionStatuses,
    loadTransactionTypes,
    addTransaction,
    updateTransaction,
    deleteTransaction,
    searchTransactions,
    updateTransactions,
    getTransactionTemplates,
    updateTransactionTemplate,
    deleteTransactionTemplate,
    moveTransaction,
    isTransaction,
  };
}
